<script setup>
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";
    import {Link, usePage} from '@inertiajs/vue3'
    import AlertComponent from '@/Components/Partials/Alert.vue'

    const homeUrl = usePage().props.homeUrl

    const props = defineProps({
        isFail: Boolean,
        accountUrl: String,
    })
</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <div v-if="isFail" class="text-danger p-4">
                    <AlertComponent type="danger" :message="trans('front_invoice.error')" />
                </div>
                <div v-else class="success-card rounded-4 shadow p-4 text-center">
                    <h1 class="success-card__title page-title fw-normal lh-sm mb-3">{{ trans('front_payment.success_title')}}</h1>
                    <p class="fs-5 lh-sm" v-html="trans('front_payment.success_text')"></p>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-5">
                            <div class="pt-2 pb-4">
                                <img class="mx-auto" src="/img/img-success.svg" alt="Thank you for your order" />
                            </div>
                            <div class="d-flex flex-column align-items-center gap-3 pt-2 pb-4">
                                <Link :href="homeUrl" class="btn btn-success no-underline w-100">
                                    {{ trans('front_payment.success_link_text') }}
                                </Link>

                                <div v-if="accountUrl" class="position-relative w-100 text-center">
                                    <hr class="divider-line">
                                    <span class="divider-text px-2">{{ trans('front_payment.or') }}</span>
                                </div>

                                <a v-if="accountUrl" :href="accountUrl" class="btn btn-outline-success no-underline w-100">
                                    {{ trans('email.layout.login_to_account') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
    .success-card {
        color: #1E4F2C;
        background-color: #D4EFDC;
        border: 1px solid #C8D4E0;

        &__title {
            font-size: 3rem;
        }
    }

    .no-underline {
        text-decoration: none;
    }

    .no-underline:hover {
        text-decoration: none;
    }

    .divider-line {
        position: absolute;
        width: 100%;
        top: 50%;
        margin: 0;
        border-top: 1px solid rgba(30, 79, 44, 0.3);
    }

    .divider-text {
        position: relative;
        background-color: #D4EFDC;
        color: #1E4F2C;
        font-size: 0.9rem;
    }

    .btn {
        max-width: 300px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
